/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import Fade from '@material-ui/core/Fade';

import { Table } from '../../../../components/NewTable';
import searchIconDetails from '../../../../assets/svg/searchIconDetails.svg';
import { ITicket, ITicketTableProps } from '../../types';
import DetailsItem from './DetailsItem';
import {
  TableFilterCardContainer,
  TableContainer,
  Container,
} from '../../pages/styles';
import TagStatus from '../../../../components/TagStatus';

const TicketsTable: React.FC<ITicketTableProps> = ({
  handleFilter,
  ticketTableRows,
  openDetail,
}) => {
  const [rows, setRows] = useState<ITicket[]>([]);

  const pendingFilter = (array: ITicket[], key: string) => {
    switch (key) {
      case 'Aberto': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Aberto';
        });
        return resultsStatus.length;
      }
      case 'Em Andamento': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Em Andamento';
        });
        return resultsStatus.length;
      }
      case 'Fechado': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Fechado';
        });
        return resultsStatus.length;
      }
      case 'Cancelado': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Cancelado';
        });
        return resultsStatus.length;
      }
      default:
        return array.length;
    }
  };

  useEffect(() => {
    setRows(ticketTableRows);
  }, [ticketTableRows]);

  return (
    <>
      <Fade in timeout={1000} unmountOnExit>
        <Container>
          <TableFilterCardContainer>
            <div>
              <button type="button" onClick={() => handleFilter({})}>
                <DetailsItem card title="Resultados" value={rows.length} />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'Open' })}
              >
                <DetailsItem
                  card
                  title="Aberto"
                  value={pendingFilter(rows, 'Aberto')}
                  id="pending"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'In Progress' })}
              >
                <DetailsItem
                  card
                  title="Em Andamento"
                  value={pendingFilter(rows, 'Em Andamento')}
                  id="ongoing"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'Cancelado' })}
              >
                <DetailsItem
                  card
                  title="Cancelado"
                  value={pendingFilter(rows, 'Cancelado')}
                  id="canceled"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'Closed' })}
              >
                <DetailsItem
                  card
                  title="Fechado"
                  value={pendingFilter(rows, 'Fechado')}
                  id="done"
                />
              </button>
            </div>
          </TableFilterCardContainer>
          <TableContainer>
            <Table<ITicket>
              columns={[
                {
                  title: 'Id',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['id'],
                  cssProps: {
                    width: '6%',
                  },
                },
                {
                  title: 'Pedido',
                  type: 'string',
                  orderable: true,
                  props: ['order'],
                  cssProps: {
                    width: '6%',
                  },
                },
                {
                  title: 'Cliente',
                  type: 'string',
                  orderable: true,
                  props: ['clientName'],
                  cssProps: {
                    width: '10%',
                  },
                },
                {
                  title: 'Loja',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['branchName'],
                  cssProps: {
                    width: '5%',
                  },
                },
                {
                  title: 'Tìtulo',
                  type: 'string',
                  orderable: true,
                  props: ['title'],
                },

                {
                  title: 'Área',
                  type: 'string',
                  orderable: true,
                  props: ['sectorName'],
                },
                {
                  title: 'Status',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['status'],
                  renderItem: row => <TagStatus status={row.status} />,
                },

                {
                  title: 'Tipo',
                  type: 'string',
                  orderable: true,
                  props: ['categoryName'],
                },

                {
                  title: 'Data Ocorrência',
                  type: 'datetime',
                  orderable: true,
                  props: ['createdAt'],
                },
                {
                  title: 'Usuário',
                  type: 'string',
                  orderable: true,
                  props: ['createdByUserName'],
                  cssProps: {
                    width: '6%',
                  },
                },
                {
                  title: 'Ações',
                  type: 'stringCenter',
                  props: ['status'],
                  renderItem: row => (
                    <div className="row-actions-button-detail">
                      <button
                        type="button"
                        className="action"
                        onClick={() => openDetail(row)}
                      >
                        <img
                          src={searchIconDetails}
                          style={{ width: '20px' }}
                          alt="detalhes"
                        />
                        <p className="hover-item">Detalhes</p>
                      </button>
                    </div>
                  ),
                },
              ]}
              rows={rows}
              exportList
              emptyDataMessage="Não foram encontrados pedidos"
              paginationOptions={[
                {
                  value: 30,
                  label: 30,
                },
                {
                  value: 50,
                  label: 50,
                },
                {
                  value: 100,
                  label: 100,
                },
              ]}
            />
          </TableContainer>
        </Container>
      </Fade>
    </>
  );
};

export default TicketsTable;
