import React from 'react';
import { Fade } from '@material-ui/core';

import { Container } from './styles';

const EditTicket: React.FC = () => {
  return (
    <Fade in timeout={1000} unmountOnExit>
      <Container />
    </Fade>
  );
};

export default EditTicket;
